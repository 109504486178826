import { APIFilter } from '@/utils/api'

export default {
  async selectLookups (Vue, idsParametros, idpresupuestocli) {
    const apiFilterEstadoActivo = new APIFilter()
      .addGT('estado', 0)
    let apiCalls = []
    apiCalls.push({ name: 'selectPeriodoCuota', method: 'periodoCuota.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectConceptoCuota', method: 'conceptoCuota.select', params: { filter: apiFilterEstadoActivo } })
    const apiFilterPresupuesto = new APIFilter()
      .addExact('idpresupuestocli', idpresupuestocli)
    apiCalls.push({ name: 'selectPresupuestocli', method: 'presupuestocli.select', params: { filter: apiFilterPresupuesto } })
    const apiFilterParamertos = new APIFilter()
      .addIn('idparametro', idsParametros)
    apiCalls.push({ name: 'selectParametro', method: 'parametro.select', params: { filter: apiFilterParamertos } })
    return await Vue.$api.batchCall(apiCalls)
  },
  async selectFormDataLookups (Vue, idconceptoCuota, idperiodoCuota) {
    const apiFilterConceptoCuota = new APIFilter()
      .addExact('idconcepto_cuota', idconceptoCuota)
    const apiFilterPeriodoCuota = new APIFilter()
      .addExact('idperiodo_cuota', idperiodoCuota)
    let apiCalls = []
    apiCalls.push({ name: 'selectConceptoCuota', method: 'conceptoCuota.select', params: { filter: apiFilterConceptoCuota } })
    apiCalls.push({ name: 'selectPeriodoCuota', method: 'periodoCuota.select', params: { filter: apiFilterPeriodoCuota } })
    return await Vue.$api.batchCall(apiCalls)
  },
  async selectFormData (Vue, id) {
    const apiFilter = new APIFilter()
      .addExact('idpresupuesto_cuota', id)
    const resp = await Vue.$api.call('presupuestoCuota.select', { filter: apiFilter })
    return resp.data.result.dataset[0]
  },
}
