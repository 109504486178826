export default {
  async update (Vue, id, formData) {
    await Vue.$api.call(
      'presupuestoCuota.update',
      {
        values: {
          idpresupuesto_cuota: id,
          idconcepto_cuota: formData.concepto_cuota.idconcepto_cuota,
          inf_comercial: formData.inf_comercial,
          idperiodo_cuota: formData.periodo_cuota.idperiodo_cuota,
          precio_mes: formData.precio_mes,
          unidades: formData.unidades,
          dto: formData.dto,
          opcional: formData.opcional,
          activacion_automatica: formData.activacion_automatica,
          meses_permanencia: formData.meses_permanencia,
          dia_mes_limite: formData.dia_mes_limite,
          meses_desde_inst: formData.meses_desde_inst,
          dia_mes_cuota: formData.dia_mes_cuota,
          meses_duracion: formData.meses_duracion,
          iva: formData.iva,
          recargo: formData.recargo,
        },
      },
    )
  },
}
